import { Authenticator } from "@aws-amplify/ui-react";
import { Outlet } from "react-router-dom";
import authConfig from "../aws-config";
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { DefaultComponents } from "@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents";
I18n.putVocabularies(translations);
I18n.setLanguage("es");
I18n.putVocabularies({
  es: {
    "Sign In": "Iniciar sesión",
    "Incorrect username or password.": "Correo y/o contraseña incorrecto/s",
    "username is required to signUp": "El correo es requerido para registrarse",
    "password is required to signUp":
      "La contraseña es requerida para registrarse",
    "Password must have at least 8 characters":
      "La cotraseña debe tener al menos 8 caracteres",
    "Password did not conform with policy: Password must have lowercase characters":
      "La contraseña debe tener al menos una letra en minúscula",
    "Password did not conform with policy: Password must have uppercase characters":
      "La contraseña debe tener al menos una letra en mayúscula",
    "Password did not conform with policy: Password must have symbol characters":
      "La contraseña debe tener al menos un símbolo",
  },
});
const formFields = {
  signIn: {
    username: {
      label: "Email",
      isRequired: true,
    },
  },
  signUp: {
    username: {
      label: "Email",
      isRequired: true,
    },
  },
  forgotPassword: {
    username: {
      placeholder: "Enter your email:",
      label: "Email",
      isRequired: true,
    },
  },
};

const components: DefaultComponents = {
  Header() {
    return (
      <div className="bg-white py-4 flex flex-column justify-content-center align-items-center">
        <div className="text-primary text-3xl font-italic">
          <i className="pi pi-bolt  text-4xl font-italic" />
          Doing-Sports
        </div>
      </div>
    );
  },
  SignUp: {
    Header() {
      return (
        <div>
          <h1 className="text-center font-bold text-primary">
            Requisitos de contraseña
          </h1>
          <ul className="font-semibold text-200">
            <li>Al menos 1 número</li>
            <li>Al menos 1 carácter especial</li>
            <li>Al menos una letra mayúscula</li>
            <li>Al menos una letra minúscula</li>
          </ul>
        </div>
      );
    },
  },
  ForgotPassword: {
    Header() {
      return (
        <h1 className="text-center font-bold text-100">Recuperar contraseña</h1>
      );
    },
  },
};
Amplify.configure(authConfig);

const AuthGuard = () => {
  return (
    <Authenticator.Provider>
      <Authenticator
        formFields={formFields}
        components={components}
        className="custom-authenticator"
      >
        <Outlet />
      </Authenticator>
    </Authenticator.Provider>
  );
};

export default AuthGuard;
