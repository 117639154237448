import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { footerLinks } from "../../../utils/constants/footer-links";

const Footer = () => {
  return (
    <>
      <div className="surface-0 flex justify-content-between m-0 mt-8 p-6">
        <div>
          <Link
            to="/"
            rel="noreferrer"
            className="font-italic no-underline flex align-items-center justify-content-center md:justify-content-start text-primary text-3xl font-bold "
          >
            <i
              className="pi pi-bolt"
              style={{ fontSize: 35, paddingRight: 2 }}
            />
            Doing-Sports
          </Link>
          <div className="flex flex-column gap-3 justify-content-end pl-2 pt-4">
            {footerLinks.map((item) => (
              <Link
                key={item.title}
                to={item.link}
                rel="noreferrer"
                className="text-gray-300 no-underline flex align-items-center"
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>

        <div className="flex flex-column text-center text-800 gap-2">
          <div className="font-italic text-2xl font-bold ">Contáctanos</div>
          <div className="text-700 font-medium pt-4">
            contacto@doing-sports.com
          </div>
          <div className="text-700 font-medium  flex align-items-center justify-content-center">
            Vía WhatsApp{" "}
            <Link to={`https://wa.me/+5493794207490`} target="_blanck">
              <Button
                severity="success"
                text
                size="small"
                icon="pi pi-whatsapp"
                tooltip="Envíar whatsapp"
                tooltipOptions={{ position: "top" }}
              />
            </Link>
          </div>
          <div className="flex justify-content-center gap-3">
            <a
              href="https://www.instagram.com/doingsports.ar"
              target="_blank"
              rel="noreferrer"
              className="text-pink-700"
            >
              <i
                className="pi pi-instagram"
                style={{ fontSize: 35, paddingRight: 2 }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/doing-sports"
              target="_blank"
              rel="noreferrer"
              className="text-blue-700"
            >
              <i
                className="pi pi-linkedin"
                style={{ fontSize: 35, paddingRight: 2 }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 text-center surface-0 text-sm">
        © 2024 by Doing-Code
      </div>
    </>
  );
};

export default Footer;
