import React from "react";

export const Dashboard = React.lazy(
  () => import("./PanelPages/Dashboard/Dashboard")
);

export const Payments = React.lazy(
  () => import("./PanelPages/Payments/Payments")
);

export const EditPlan = React.lazy(() => import("./PanelPages/Plans/EditPlan"));
export const NewPlan = React.lazy(() => import("./PanelPages/Plans/NewPlan"));
export const Plans = React.lazy(() => import("./PanelPages/Plans/Plans"));

export const SearchSports = React.lazy(
  () => import("./PanelPages/Sports/SearchSports")
);
export const SportMonthlyFees = React.lazy(
  () => import("./PanelPages/Sports/SportMonthlyFees")
);
export const SportRequests = React.lazy(
  () => import("./PanelPages/Sports/SportRequests")
);

export const Profile = React.lazy(() => import("./PanelPages/Profile/Profile"));
export const SignOut = React.lazy(() => import("./PanelPages/SignOut/SignOut"));

export const Institutions = React.lazy(
  () => import("./PanelPages/Institutions/Institutions")
);
export const NewInstitutions = React.lazy(
  () => import("./PanelPages/Institutions/NewInstitutions")
);
export const SeeInstitution = React.lazy(
  () => import("./PanelPages/Institutions/SeeInstitution")
);

export const UpdateInstitution = React.lazy(
  () => import("./PanelPages/Institutions/UpdateInstitution")
);

export const NewUser = React.lazy(() => import("./PanelPages/Users/NewUser"));
export const Users = React.lazy(() => import("./PanelPages/Users/Users"));
export const SeeUser = React.lazy(() => import("./PanelPages/Users/SeeUser"));

export const Panel = React.lazy(() => import("./PanelPages/Panel/Panel"));

export const Home = React.lazy(() => import("./PublicPages/Home/Home"));

export const MyInstitutions = React.lazy(
  () => import("./PanelPages/MyInstitutions/MyInstitutions")
);

export const SeeMyInstitution = React.lazy(
  () => import("./PanelPages/MyInstitutions/SeeMyInstitution")
);

export const PlansPublic = React.lazy(
  () => import("./PublicPages/PlansPublic")
);
export const SportsPublic = React.lazy(
  () => import("./PublicPages/SportsPublic")
);
export const InstitutionsPublic = React.lazy(
  () => import("./PublicPages/InstitutionsPublic")
);
export const HelpPublic = React.lazy(() => import("./PublicPages/HelpPublic"));
export const ManageSports = React.lazy(
  () => import("./PanelPages/ManageSports/ManageSports")
);
